<template>

    <div class="col-md-8 col-lg-4 px-4 mx-auto mt-5 pt-5 pt-sm-4" >
      <div class="form-container mx-2">
          <div class="form-header">
            <span>{{ $t('forgot-password.title') }}</span>
          </div>
          <div class="card-body">
            <div class="form-group mb-4">
              <label for="mobile" class="text-left">{{ $t('forgot-password.mobile') }}:</label>
              <input v-model="form.mobile" id="mobile" type="text" class="form-control" :placeholder="$t('forgot-password.mobile-label')">
            </div>

            <div class="form-group mb-4">
              <label for="personal_id" class="text-left">{{ $t('forgot-password.personal-number') }}:</label>
              <input v-model="form.personal_id" id="personal_id" type="text" class="form-control" :placeholder="$t('forgot-password.personal-number-label')">
            </div>
            <div class="text-center pt-3 pt-sm-4">
              <button @click="resetPassword" type="button" class="btn btn-primary-red-10 ">
                  {{ $t('forgot-password.send') }}
              </button>
            </div>
          </div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'forgot-password',

  data() {
    return {
      form: {
        personal_id: '',
        mobile: '',
      },
    };
  },

  methods: {
    resetPassword() {
      this.$store.dispatch('auth/resetPassword', this.form)
        .then((response) => {
          this.helpers.notifySuccessMessage(this.$t('forgot-password.success-message'))

          this.$router.push('/login')
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error)
        });
    },
  },
};
</script>

<style scoped>


</style>
